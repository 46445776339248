import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { collection, query, where, getDocs } from "firebase/firestore";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Typography, TextField, Box, Button, Stack } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import { Pagination } from "../../firebase/operations";

import { EnhancedTableToolbar } from "./businessToolbar";
import CircularStatic from "../shared/Loader/loader";

import { tableStyles } from "../styles/tableStyles";
import KYCMoreMenu from "../Users/KYC/kycMoreMenu";
import { db } from "../../firebase/firebase";
import PropTypes from "prop-types";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: "true",
});

const getMuiTheme = () => createTheme(tableStyles);

Business.propTypes = {
  viewMode: PropTypes.string.isRequired,
};

export default function Business(props) {
  const { viewMode } = props;

  const [rows, setRows] = useState([]); // table rows
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lastKey, setLastKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const [createdFrom, setCreatedFrom] = useState(null);
  const [createdTo, setCreatedTo] = useState(null);
  const [updatedFrom, setUpdatedFrom] = useState(null);
  const [updatedTo, setUpdatedTo] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const searchRef = useRef(null);

  const whereClause = viewMode === "businesses" ? ["paymentOption", "in", ["till", "paybill"]] : null;

  // handle download of filtered data
  const handleDownload = (buildHead, buildBody, columns, data) => {
    const filteredData = data.map((row) => row.data);

    // Create a CSV string from the filtered data
    const csvData = [buildHead(columns), ...buildBody(filteredData)].join("\n");

    // Create a download link and click it programmatically
    const downloadLink = document.createElement("a");
    const blob = new Blob(["\ufeff", csvData]);
    const url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = "data.csv";
    downloadLink.click();
    URL.revokeObjectURL(url);
  };

  const handleSearch = async (input) => {
    const namesMatched = [];
    const merchantNoMatches = [];
    const onBoardedNoMatches = [];
    const channelNoMatches = [];
    let phoneNumMatches = [];

    let numCheck = false;

    const numRegex = /^\d+$/;

    const inputCheck = (string) => {
      if (string.match(numRegex)) {
        numCheck = true;
        return numCheck;
      }

      return numCheck;
    };

    inputCheck(input);



    // check for numbers
    if (numCheck) {
      if (input.length > 4) {
        // get merchant number match query
        const searchMerchantNoQuery = query(
          collection(db, "merchant_businesses"),
          where("phoneNumber", "in", [input, `+${input}`])
        );

        // get on boarded by number match query
        const searchOnBoardedByNoQuery = query(
          collection(db, "merchant_businesses"),
          where("onBoardedBy", "in", [input, `+${input}`])
        );

        const merchantNoSnapshot = await getDocs(searchMerchantNoQuery);
        const onBoardedByNoSnapshot = await getDocs(searchOnBoardedByNoQuery);

        merchantNoSnapshot.forEach((doc) => {
          merchantNoMatches.push({ ...doc.data(), docId: doc.id });
        });

        onBoardedByNoSnapshot.forEach((doc) => {
          onBoardedNoMatches.push({ ...doc.data(), docId: doc.id });
        });

        phoneNumMatches = merchantNoMatches.concat(onBoardedNoMatches);

        setRows(phoneNumMatches);
      } else {
        // get channel number match query
        const searchChannelNoQuery = query(
          collection(db, "merchant_businesses"),
          where("channel", "==", input)
        );

        const channelNoSnapshot = await getDocs(searchChannelNoQuery);

        channelNoSnapshot.forEach((doc) => {
          channelNoMatches.push({ ...doc.data(), docId: doc.id });
        });

        setRows(channelNoMatches);
      }
    } else {
      // check for names
      const searchNameQuery = query(
        collection(db, "merchant_businesses"),
        where("businessName", "==", input)
      );

      const nameSnapshot = await getDocs(searchNameQuery);

      nameSnapshot.forEach((doc) => {
        namesMatched.push({ ...doc.data(), docId: doc.id });
      });

      setRows(namesMatched);
    }
  };

  const filterList = {};

  // handle filter
  const handleFilterSubmit = (applyFilters) => {
    const filters = applyFilters();
    filters.map((item) => {
      const key = columns[`${filters.indexOf(item)}`].name;
      let newFilter;
      if (key === "createdAt") {
        newFilter = {
          createdFrom: moment(item[0]).format("DD-MM-YYYY"),
          createdTo: moment(item[1]).format("DD-MM-YYYY"),
        };
      }
      if (key === "updatedAt") {
        newFilter = {
          updatedFrom: moment(item[0]).format("DD-MM-YYYY"),
          updatedTo: moment(item[1]).format("DD-MM-YYYY"),
        };
      } else {
        newFilter = { [key]: moment(item[0]).format("DD-MM-YYYY") };
      }
      Object.assign(filterList, newFilter);
    });
    setIsFiltered(true);
  };

  const columns = [
    {
      name: "businessName",
      label: "Business Name",
      options: {
        filter: false,
      },
    },
    {
      name: "businessType",
      label: "Business Type",
      options: {
        filter: true,
      },
    },
    {
      name: "businessIndustry",
      label: "Business Sector",
      options: {
        filter: true,
      },
    },
    {
      name: "channel",
      label: "Business Channel",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          if (rows[dataIndex].channel) {
            return rows[dataIndex].channel;
          } else {
            return (
              <Typography
                sx={{ fontFamily: "Rubik, sans-serif", fontSize: "0.88rem" }}
              >
                N/A
              </Typography>
            );
          }
        },
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        filter: false,
      },
    },
    {
      name: "onBoardedBy",
      label: "Onboarded By",
      options: {
        filter: false,
      },
    },
    {
      name: "hakikishaStatus",
      label: "Hakikisha Status",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const businessData = rows[dataIndex];

            if (businessData.hakikishaStatus === "verified") {
                return (
                    <Typography
                    sx={{
                        color: "#009966",
                        fontFamily: "Rubik, sans-serif",
                        fontWeight: "600",
                        fontSize: ".88rem",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "rgba(0,153,102,.2)",
                        padding: ".2rem",
                        width: "100%",
                        borderRadius: "7px",
                    }}
                    >
                    <span
                        style={{
                        marginRight: ".5rem",
                        }}
                    >
                        <CircleIcon sx={{ fontSize: ".6rem", color: "#009966" }} />
                    </span>
                    Verified
                    </Typography>
                );
            } else {
                return (
                    <Typography
                    sx={{
                        color: "#ED7014",
                        fontFamily: "Rubik, sans-serif",
                        fontWeight: "600",
                        fontSize: ".88rem",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "rgba(237,112,20,.2)",
                        padding: ".2rem",
                        width: "100%",
                        borderRadius: "7px",
                    }}
                    >
                    <span
                        style={{
                        marginRight: ".5rem",
                        }}
                    >
                        <CircleIcon sx={{ fontSize: ".6rem", color: "#ED7014" }} />
                    </span>{" "}
                    Not Available
                    </Typography>
                );
            }
        }
      },
    },
    {
      name: "businessVerificationStatus",
      label: "Business Verified",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          if (rows[dataIndex].businessVerificationStatus === "verified") {
            return (
              <Typography
                sx={{
                  color: "#009966",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "600",
                  fontSize: ".88rem",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(0,153,102,.2)",
                  padding: ".2rem",
                  width: "100%",
                  borderRadius: "7px",
                }}
              >
                <span
                  style={{
                    marginRight: ".5rem",
                  }}
                >
                  <CircleIcon sx={{ fontSize: ".6rem", color: "#009966" }} />
                </span>
                Verified
              </Typography>
            );
          } else if (
            rows[dataIndex].businessVerificationStatus === "declined"
          ) {
            return (
              <Typography
                sx={{
                  color: "red",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "600",
                  fontSize: ".88rem",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(255,0,0,.2)",
                  padding: ".2rem",
                  width: "100%",
                  borderRadius: "7px",
                }}
              >
                <span
                  style={{
                    marginRight: ".5rem",
                  }}
                >
                  <CircleIcon sx={{ fontSize: ".6rem", color: "red" }} />
                </span>{" "}
                Declined
              </Typography>
            );
          } else {
            return (
              <Typography
                sx={{
                  color: "#ED7014",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "600",
                  fontSize: ".88rem",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(237,112,20,.2)",
                  padding: ".2rem",
                  width: "100%",
                  borderRadius: "7px",
                }}
              >
                <span
                  style={{
                    marginRight: ".5rem",
                  }}
                >
                  <CircleIcon sx={{ fontSize: ".6rem", color: "#ED7014" }} />
                </span>{" "}
                Pending
              </Typography>
            );
          }
        },
      },
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {

              return [
                `Last Created From: ${moment(v[0]).format("MM-DD-YYYY")}`,
                `Last Created To: ${moment(v[1]).format("MM-DD-YYYY")}`,
              ];
            }
            if (v[0]) {
              return `Last Created From: ${moment(v[0]).format("MM-DD-YYYY")}`;
            }
            if (v[1]) {
              return `Last Created To: ${moment(v[1]).format("MM-DD-YYYY")}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(createdAt, filters) {
            const formattedDate =
              typeof createdAt === "string"
                ? moment(createdAt).format("MM-DD-YYYY")
                : moment(createdAt.toDate().toDateString()).format(
                    "MM-DD-YYYY"
                  );



            if (filters[0] && filters[1]) {
              let formattedTo = moment(filters[1]).format("MM-DD-YYYY");
              let formattedFrom = moment(filters[0]).format("MM-DD-YYYY");

              return (
                moment(formattedDate, "MM-DD-YYYY").isBetween(
                  formattedFrom,
                  formattedTo
                ) === false
              );
            }
            if (filters[1]) {
              let formattedFrom = moment(filters[1]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrAfter(formattedFrom);
            }
            if (filters[0]) {
              let formattedTo = moment(filters[0]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrBefore(formattedTo);
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Created From:"
                  value={createdFrom || filterList[index][0]}
                  onChange={(newValue) => {
                    setCreatedFrom(newValue);
                    filterList[index][0] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Created To:"
                  value={createdTo || filterList[index][1]}
                  onChange={(newValue) => {
                    setCreatedTo(newValue);
                    filterList[index][1] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Stack>
          ),
        },
        customBodyRenderLite: (dataIndex) => {
          return typeof rows[dataIndex].createdAt === "string"
            ? moment(rows[dataIndex].createdAt).format(
                "MMMM Do YYYY, h:mm:ss a"
              )
            : moment(rows[dataIndex].createdAt.toDate()).format(
                "MMMM Do YYYY, h:mm:ss a"
              );
        },
      },
    },
    {
      name: "updatedAt",
      label: "Updated At",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              return [
                `Last Updated From: ${moment(v[0]).format("MM-DD-YYYY")}`,
                `Last Updated To: ${moment(v[1]).format("MM-DD-YYYY")}`,
              ];
            }
            if (v[0]) {
              return `Last Updated From: ${moment(v[0]).format("MM-DD-YYYY")}`;
            }
            if (v[1]) {
              return `Last Updated To: ${moment(v[1]).format("MM-DD-YYYY")}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(updatedAt, filters) {
            const formattedDate =
              typeof updatedAt === "string"
                ? moment(updatedAt).format("MM-DD-YYYY")
                : moment(updatedAt?.toDate().toDateString()).format(
                    "MM-DD-YYYY"
                  );



            if (filters[0] && filters[1]) {
              let formattedTo = moment(filters[1]).format("MM-DD-YYYY");
              let formattedFrom = moment(filters[0]).format("MM-DD-YYYY");

              return (
                moment(formattedDate, "MM-DD-YYYY").isBetween(
                  formattedFrom,
                  formattedTo
                ) === false
              );
            }
            if (filters[1]) {
              let formattedFrom = moment(filters[1]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrAfter(formattedFrom);
            }
            if (filters[0]) {
              let formattedTo = moment(filters[0]).format("MM-DD-YYYY");
              return moment(formattedDate).isSameOrBefore(formattedTo);
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Updated From:"
                  value={updatedFrom || filterList[index][0]}
                  onChange={(newValue) => {
                    setUpdatedFrom(newValue);
                    filterList[index][0] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Updated To:"
                  value={updatedTo || filterList[index][1]}
                  onChange={(newValue) => {
                    setUpdatedTo(newValue);
                    filterList[index][1] = newValue;
                    onChange(filterList[index], index, column);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Stack>
          ),
        },
        customBodyRenderLite: (dataIndex) => {
          return typeof rows[dataIndex].updatedAt === "string"
            ? moment(rows[dataIndex].updatedAt).format(
                "MMMM Do YYYY, h:mm:ss a"
              )
            : moment(rows[dataIndex].updatedAt.toDate()).format(
                "MMMM Do YYYY, h:mm:ss a"
              );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          if (rows[dataIndex]) {
            return (
              <KYCMoreMenu
                businessId={rows[dataIndex]["docId"]}
                businessData={rows[dataIndex]}
                viewMode={viewMode}
                refresh={fetchData}
              />
            );
          }
        },
      },
    },
  ];

  if (viewMode === 'merchant_businesses'){
    // add column after onBoardedBy
    columns.splice(5, 0, {
      name: "status",
      label: "Docs Upload Status",
      options: {
        filter: true,
      },
    });
  }

  if (viewMode === 'businesses'){
    columns.splice(2, 0, {
      name: "paybillOrTill",
      label: "Paybill/Till Number",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          if (rows[dataIndex].payBillNo) {
            return rows[dataIndex].payBillNo;
          } else if (rows[dataIndex].tillNo) {
            return rows[dataIndex].tillNo;
          } else {
            return (
              <Typography
                sx={{ fontFamily: "Rubik, sans-serif", fontSize: "0.88rem" }}
              >
                N/A
              </Typography>
            );
          }
        },
      },
    });

    columns.splice(3, 0, {
      name: "accountNo",
      label: "Account Number",
      options: {
        filter: false,
      },
    });
  }

  const handleChangePage = (tableState) => {
    const { page } = tableState;
    setPage(page);

    const fetchMoreData = (key) => {
      if (key) {
        Pagination.fetchNextBatch(key, "merchant_businesses", "createdAt", whereClause)
          .then((res) => {
            setLastKey(res.lastKey);
            setRows(
              rows.concat(
                res.fetchedData.filter(
                  (row) => row.businessVerificationStatus !== undefined
                )
              )
            );
            setIsLoading(false);
          })
          .catch((err) => {
            console.log("Err: ", err);
          });
      }
    };

    fetchMoreData(lastKey);

  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage, 10));
    setPage(0);
  };

  const options = {
    responsive: "standard",
    rowsPerPage,
    rowsPerPageOptions: [10, 25, 50, 100],
    download: true,
    print: false,
    confirmFilters: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handleChangePage(tableState);
          break;
        case "changeRowsPerPage":
          handleChangeRowsPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
      <Box style={{ marginTop: "40px" }}>
        <Button
          variant="contained"
          sx={{
            fontFamily: "Rubik, sans-serif",
            fontWeight: "500",
            fontSize: ".88rem",
            backgroundColor: "#009966",

            "&:hover": {
              backgroundColor: "rgba(0,153,102,.8)",
            },
          }}
          onClick={() => handleFilterSubmit(applyNewFilters)}
        >
          Apply Filters
        </Button>
      </Box>
    ),
    downloadOptions: {
      filename: "data.csv",
      separator: ",",
      filterOptions: {
        useDisplayedData: true, // Use displayed data instead of all data
      },
      customDownload: (buildHead, buildBody, columns, data) =>
        handleDownload(buildHead, buildBody, columns, data),
    },
  };

  // pull data from firebase and set table rows
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setIsLoading(true);
    Pagination.fetchFirstBatch("merchant_businesses", "createdAt", whereClause)
        .then((res) => {
          setRows(
              res.fetchedData.filter(
                  (row) => row.businessVerificationStatus !== undefined
              )
          );
          setLastKey(res.lastKey);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("Err: ", err);
        });
  }



  return (
    <div style={{ backgroundColor: "#F7F7F7" }}>
      <EnhancedTableToolbar
        setSearchInput={setSearchInput}
        searchRef={searchRef}
        viewMode={viewMode}
        handleSearch={() => handleSearch(searchInput)}
        refresh={fetchData}
      />
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme()}>
          {isLoading ? (
            <CircularStatic />
          ) : (
            <MUIDataTable data={rows} columns={columns} options={options} />
          )}
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
}
